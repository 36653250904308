<template>
  <div class="parlay-game" :class="{ 'parlay-game--mobile': isMobile, 'parlay-game--is-tip': isTip }">
    <div v-if="bet.isVisible === false" class="parlay-game__disabled" />
    <div class="parlay-game__league">
      <span class="name">{{ bet.tournamentName || bet.tournament_name }}</span> |
      <span class="round">{{ roundName }}</span>
    </div>
    <div class="parlay-game__container">
      <div class="parlay-game__teams" @mouseenter="onMouseEnter">
        <div
          class="parlay-game__teams__team"
          :class="{ 'parlay-game__teams__team--mobile': isMobile, 'is-selected': selectedResult === 1 }"
        >
          <div class="name-logo home" :class="{ 'name-logo--mobile': isMobile }">
            <div class="name">
              {{ bet.home_team_acronym }}
            </div>
            <div class="logo">
              <img :src="`https://az755631.vo.msecnd.net/teams-80/${bet.home_team_logo}`" alt="" />
            </div>
          </div>
          <div v-if="selectedResult === 1" class="result-icon">
            <img :src="resultIcon" alt="" />
          </div>
        </div>
        <div class="parlay-game__teams__vs" :class="{ 'is-selected': selectedResult === 3 }">
          <div>VS</div>
          <div class="vs-icon" v-if="selectedResult === 3">
            <div class="result-icon">
              <img src="/icons/parlay_win.svg" alt="" />
            </div>
          </div>
        </div>
        <div
          class="parlay-game__teams__team"
          :class="{ 'parlay-game__teams__team--mobile': isMobile, 'is-selected': selectedResult === 2 }"
        >
          <div class="name-logo" :class="{ 'name-logo--mobile': isMobile }">
            <div class="name">
              {{ bet.away_team_acronym }}
            </div>
            <div class="logo">
              <img :src="`https://az755631.vo.msecnd.net/teams-80/${bet.away_team_logo}`" alt="" />
            </div>
          </div>
          <div v-if="selectedResult === 2" class="result-icon">
            <img :src="resultIcon" alt="" />
          </div>
        </div>
        <div v-if="isGameInfoVisible" class="parlay-game__teams__game-info" @mouseleave="onMouseLeave" @click="onClick">
          <div class="prop">
            Fecha:
          </div>
          <div class="info">
            {{ bet.date | toFormattedDate('d MMM yyyy', true) }}
          </div>
          <div class="prop stadium">
            Hora:
          </div>
          <div class="info">
            {{ getGameTime(bet.game_hour) }}
          </div>
        </div>
      </div>
      <div class="parlay-game__risk">
        <div class="indicator" :style="riskBackgroundColor" />
        <div class="text">{{ riskText }}</div>
      </div>
      <div class="parlay-game__result">
        {{ betText }}
      </div>
      <div class="parlay-game__bet">
        <div class="bet-number" @click="onOddClick(bet.bet_ref, bet.game_id, bet.odd, bet)">
          <span v-if="bet.odd > 0">+</span>{{ bet.odd }}
        </div>
        <div class="caliente">
          <img src="https://az755631.vo.msecnd.net/golstats-bets/caliente_2020.svg" alt="" />
        </div>
      </div>
      <template v-if="isTip">
        <div class="parlay-game__amount-text">
          Monto a apostar
        </div>
        <div class="parlay-game__amount-number">
          <span>$</span><span>{{ bet.investment.toFixed(2) }}</span
          ><span>mxn</span>
        </div>
      </template>
    </div>
    <div v-if="isTip" class="parlay-game__simulator">
      <TipsSwitchSimulator v-model="bet.isVisible" />
    </div>
  </div>
</template>

<script>
import { doBetcaliente } from '@/utils/analytics';
import { mapGetters } from 'vuex';
import { regularLeague, cup } from '@/utils/play-off-abbreviations';

export default {
  name: 'ParlayGame',
  components: {
    TipsSwitchSimulator: () => import('@/components/Sections/GolTipster-v2/Tips/TipsSwitchSimulator'),
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    isTip: {
      type: Boolean,
      default: false,
    },
    bet: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isGameInfoVisible: false,
      isDisabled: false,
    };
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    roundName() {
      let roundName = this.bet.matchday_abbreviation.replace('M', 'Jornada ');
      if (this.bet.isCup) {
        roundName = cup[this.bet.matchday_abbreviation] ? this.bet.matchday_abbreviation : roundName;
      } else {
        roundName = regularLeague[this.bet.matchday_abbreviation] || roundName;
      }
      return roundName;
    },
    selectedResult() {
      if (this.bet.bet_subtype_id === 2) {
        return 3;
      }
      return this.bet.bet_suggested.home_team_logo ? 1 : 2;
    },
    riskBackgroundColor() {
      let color = '';
      switch (this.bet.bet_risk) {
        case 1:
          color = '#9ecb26';
          break;
        case 2:
          color = '#f1d021';
          break;
        case 3:
          color = '#f17921';
          break;
        default:
          color = 'white';
      }
      return { 'background-color': color };
    },
    riskText() {
      let text = '';
      switch (this.bet.bet_risk) {
        case 1:
          text = 'Bajo';
          break;
        case 2:
          text = 'Medio';
          break;
        case 3:
          text = 'Alto';
          break;
        default:
          text = '';
      }
      return `${text} Riesgo`;
    },
    betText() {
      let text = '';
      if (this.bet.bet_subtype_id) {
        switch (this.bet.bet_subtype_id) {
          case 1:
            text = 'Gana';
            break;
          case 2:
            text = 'Empate';
            break;
          case 3:
            text = 'Gana / Empata';
            break;
          case 4:
            text = 'Gana / Gana';
            break;
          default:
            text = '';
        }
      } else {
        text =
          this.bet.bet_suggested.suggestion === 'G/E'
            ? 'Gana / Empata'
            : this.bet.bet_suggested.suggestion === 'G/G'
            ? 'Gana / Gana'
            : this.bet.bet_suggested.suggestion;
      }
      return text;
    },
    resultIcon() {
      return this.bet.bet_subtype_id === 1 ? '/icons/parlay_win.svg' : '/icons/parlay_draw.svg';
    },
  },
  methods: {
    getGameTime(gameHour) {
      const hoursAndMinutes = gameHour.split(':');
      hoursAndMinutes.pop();
      return hoursAndMinutes.join(':') + ' hrs.';
    },
    onSwitchInput($event) {
      this.isDisabled = $event;
      this.bet.isDisabled = $event;
    },
    onOddClick(betRef, gameId, odd, bet) {
      let betNames = {};
      if (this.isTip) {
        betNames = {
          1: bet =>
            bet.home_team_logo === bet.bet_suggested.home_team_logo
              ? `${bet.home_team.name} Gana`
              : `${bet.away_team.name} Gana`,
          2: bet => `${bet.home_team.name} y ${bet.away_team.name} Empatan`,
          3: bet =>
            bet.home_team_logo === bet.bet_suggested.home_team_logo
              ? `${bet.home_team.name} Gana / Empata`
              : `${bet.away_team.name} Gana / Empata`,
        };
      } else {
        betNames = {
          1: bet =>
            bet.home_team_logo === bet.bet_suggested.home_team_logo
              ? `${bet.home_team_name} Gana`
              : `${bet.away_team_name} Gana`,
          2: bet => `${bet.home_team_name} y ${bet.away_team_name} Empatan`,
          3: bet =>
            bet.home_team_logo === bet.bet_suggested.home_team_logo
              ? `${bet.home_team_name} Gana / Empata`
              : `${bet.away_team_name} Gana / Empata`,
        };
      }
      if (betRef) {
        window.open(
          `https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=${betRef}`,
        );
        doBetcaliente({
          is_premium: this.getPremiumAccount,
          golstats: this.getIsGolstats,
          value: betRef,
          odd,
          section: this.isTip ? 'Tips' : 'Parlay',
          game_id: gameId,
          bet_name: betNames.hasOwnProperty(bet.bet_subtype_id) ? betNames[bet.bet_subtype_id](bet) : '',
        });
      }
    },
    onMouseEnter() {
      this.isGameInfoVisible = true;
    },
    onMouseLeave() {
      this.isGameInfoVisible = false;
    },
    onClick() {
      // this.isGameInfoVisible = false;
    },
  },
  mounted() {
    this.isDisabled = this.bet.isDisabled;
  },
};
</script>

<style scoped lang="scss">
$robotoRegular: Roboto-Regular, sans-serif;
.parlay-game {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  width: 12.5em;
  position: relative;

  &__disabled {
    position: absolute;
    top: 0;
    height: 85%;
    width: 100%;
    background-color: white;
    opacity: 0.5;
    z-index: 2;
  }

  &--is-tip {
    width: 14.05em;

    & .parlay-game__league {
      width: 82%;
    }

    & .parlay-game__container {
      margin-top: 0.625em;
      padding: 0.2em 0.78em 1.375em;
      border-radius: 24px;
      border: solid 1px #132839;
    }
  }

  &--mobile {
    width: 100%;
  }

  &__container {
    width: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__league {
    border-radius: 6px;
    background-color: #132839;
    width: 96%;
    font-size: 0.875em;
    font-family: $robotoRegular;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    color: #fff;
    padding: 0.5em 0;

    & > span {
      &:first-child {
        margin-right: 0.5em;
      }
      &:last-child {
        margin-left: 0.5em;
      }
    }
  }

  &__teams {
    display: grid;
    grid-template-columns: 1fr min-content 1fr;
    width: 100%;
    border-radius: 1em;
    background-image: radial-gradient(circle at 50% 50%, #08f, #02335f 50%);
    padding: 0.28em 0.4em;
    margin-top: 0.3em;
    color: white;
    font-family: $robotoRegular;
    position: relative;
    height: 7.8em;

    &__game-info {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(20, 31, 41, 0.9);
      border-radius: 1em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      font-family: Roboto-Regular, sans-serif;
      font-size: 0.75em;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.35;
      letter-spacing: normal;

      & > div {
        &.prop {
          font-weight: bold;

          &.stadium {
            margin-top: 0.5em;
          }
        }

        &.info {
          text-transform: uppercase;
        }
      }
    }

    &__team {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0.6em 0 0.3em;

      &--mobile {
        padding: 1em 0.35em;

        & > div.result-icon {
          margin-top: 0.5em;
        }
      }

      & > div.name-logo {
        display: flex;
        flex-direction: column;

        & > div.name {
          font-size: 0.875em;
          font-family: Roboto-Bold, sans-serif;
          font-weight: bold;
        }

        & > div.logo {
          width: 3.5em;
          height: 3.5em;
          margin: 0.1em 0 0.3em;
          filter: drop-shadow(0 6px 4px rgba(0, 0, 0, 0.21));

          @media screen and (max-width: 330px) {
            width: 2.8em;
            height: 2.8em;
          }

          & > img {
            object-fit: contain;
          }
        }

        &--mobile {
          flex-direction: row;
          align-items: center;

          & > div.name {
            font-size: 1.02em;
            font-weight: 800;
            margin: 0.25em;
          }

          & > div.logo {
            margin: 0 0.25em;
          }

          &.name-logo.home {
            flex-direction: row-reverse;
          }
        }
      }
    }

    &__vs {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0.75em;
      font-size: 1em;
      font-weight: 600;
      position: relative;

      & > div.vs-icon {
        position: absolute;
        bottom: 0.3em;
      }
    }
  }

  &__risk {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2em 0;

    & > div.indicator {
      height: 0.875em;
      width: 0.875em;
      border-radius: 50%;
    }

    & > div.text {
      font-size: 0.75em;
      color: #132839;
      padding-top: 0.2em;
      margin-left: 0.5em;
    }
  }

  &__result {
    width: 100%;
    margin-top: 0.1em;
    border-radius: 10px;
    background-color: #cbee6b;
    color: black;
    text-align: center;
    padding: 0.525em 0 0.425em;
    font-size: 1.5em;
    line-height: 0.74;
    font-family: Roboto-Black, sans-serif;
  }

  &__bet {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 0.3em;

    & > div {
      align-self: center;

      &.bet-number {
        justify-self: flex-end;
        line-height: 0.67;
        padding-right: 1.6em;
        text-decoration: underline;
        font-size: 1em;
        font-family: $robotoRegular;
        font-weight: bold;
        color: #132839;
        cursor: pointer;

        &:hover {
          color: #9ccb1c;
        }
      }

      &.caliente {
        width: 3.7em;

        & > img {
          padding-top: 0.2em;
          object-fit: contain;
        }
      }
    }
  }

  &__amount-text {
    width: 100%;
    border-radius: 6px;
    background-color: #024178;
    color: white;
    font-family: Roboto-Regular, sans-serif;
    font-size: 0.8125em;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    letter-spacing: normal;
    text-align: center;
  }

  &__amount-number {
    width: 100%;
    margin-top: 0.1rem;
    border-radius: 6px;
    background-color: #dceffe;
    font-family: Roboto-Regular, sans-serif;
    font-size: 1.125rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.99;
    letter-spacing: normal;
    text-align: left;
    color: #132839;
    padding: 0.5em 0 0.5em 0.75em;

    & > span {
      margin: 0.3em;

      &:last-child {
        text-transform: uppercase;
      }
    }
  }

  &__simulator {
    margin-top: 1.25em;
    width: 74%;
  }
}

.is-selected {
  background-color: #0f92ff;
  border-radius: 1em;
}

div.result-icon {
  height: 1.125em;
  width: 1.56em;
}
</style>
